@import '../global.scss';
.project-info {
    position: relative;
    background: linear-gradient(183deg, #FFFFFF 8.01%, #E3F6FD 51.24%, #E3F6FD 78.99%, #67C8F4 97.49%);
    .content {
        max-width: 1440px;
        margin: auto;
        padding: 96px 108px;
        .top {
            display: flex;
            flex-direction: column;
            &-logo {
                align-self: end;
            }
            &-breadcrumb {
                margin-top: 24px;
                span {
                    color: #666464;
                }
                span:hover {
                    color: $primary;
                    cursor: pointer;
                }
                .bread {
                    &-award-categories {
                        color: #666464;
                    }
                    &-award-categories:hover {
                        color: $primary;
                        cursor: pointer;
                    }
                    &-project-info {
                        color: #000000;
                    }
                }
            }
            &-title {
                margin-top: 72px;
                display: flex;
                gap: 8px;
                img {
                    object-fit: contain;
                }
            }
            &-desc {
                margin-top: 24px;
            }
            &-instruction {
                margin-top: 24px;
                display: flex;
                gap: 24px;
            }
        }
        .main {
            margin-top: 48px;
            &-btn {
                display: flex;
                justify-content: end;
                button {
                    z-index: 10;
                }
            }
            .btn {
                &-scoring {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    width: 184px;
                    height: 42px;
                    background: linear-gradient(180.84deg, $primary 17.76%, #1D266F 84.98%);
                    color: #ffffff;
                    border-radius: 4px;
                }
                &-scoring:hover {
                    background: $primary;
                }
            }
            &-project-detail {
                margin-top: 48px;
                background-color: #ffffff;
                box-shadow: 4px 4px 24px 0px #00000014;
                padding: 48px 104px;
                h1 {
                    color: #1D266F;
                }
                .project {
                    &-title {
                        word-break: break-all;
                        display: flex;
                        flex-direction: column;
                        gap: 8px;
                    }
                    &-information {
                        display: flex;
                        gap: 28px;
                        margin-top: 36px;
                        .information-left {
                            width: 493px;
                            display: flex;
                            flex-direction: column;
                            gap: 16px;
                            .topic {
                                display: flex;
                                align-items: center;
                                gap: 62px;
                                h3 {
                                    width: 133px;
                                }
                                .country-detail {
                                    align-items: center;
                                    display: flex;
                                    gap: 8px;
                                }
                            }
                            .team-name {
                                align-items: start;
                                p {
                                    width: 280px;
                                }
                            }
                            .email {
                                align-items: start;
                                p {
                                    width: 280px;
                                }
                            }
                            .team-members {
                                align-items: start;
                                ul li {
                                    width: 280px;
                                    margin-bottom: 8px;
                                }
                            }
                            .additional-file {
                                h1 {
                                    margin-bottom: 28px;
                                }
                                .file-name {
                                    overflow: hidden;
                                    text-overflow: ellipsis;
                                    display: -webkit-box;
                                    -webkit-line-clamp: 1;
                                    -webkit-box-orient: vertical;
                                    white-space: normal;
                                }
                            }
                        }
                        .information-right {
                            width: 495px;
                            display: flex;
                            flex-direction: column;
                            gap: 24px;
                            .video {
                                display: flex;
                                flex-direction: column;
                                gap: 8px;
                                &-caution {
                                    margin-left: 12px;
                                    align-items: center;
                                    display: flex;
                                    gap: 8px;
                                    color: #9D9D9D;
                                }
                                &-href {
                                    margin-left: 4px;
                                    color: $primary;
                                    word-wrap: break-word;
                                    word-break: break-all;
                                }
                                &-href:hover {
                                    text-decoration: underline;
                                }
                            }
                            .infographic {
                                .cover-img {
                                    position: relative;
                                    display: inline-block;
                                }
                                
                                .cover-img .info-img {
                                    transition: filter 0.3s ease;
                                }
                                
                                .cover-img:hover .info-img {
                                    filter: brightness(50%);
                                }
                                
                                .cover-img .view-text {
                                    display: none;
                                    position: absolute;
                                    top: 55%;
                                    left: 50%;
                                    transform: translate(-50%, -50%);
                                    color: white;
                                    border: 2px solid white;
                                    border-radius: 4px;
                                    padding: 10px 20px;
                                    font-size: 18px;
                                    text-align: center;
                                    cursor: pointer;
                                }
                                
                                .cover-img:hover .view-text {
                                    display: block;
                                }
                                .info-img {
                                    margin-top: 28px;
                                    width: 500px;
                                    height: 280px;
                                    object-fit: fill;
                                }
                                .image-preview-overlay {
                                    position: fixed;
                                    top: 0;
                                    left: 0;
                                    right: 0;
                                    bottom: 0;
                                    background-color: rgba(0, 0, 0, 0.7);
                                    display: flex;
                                    justify-content: center;
                                    align-items: center;
                                    z-index: 1000;
                                  }
                                  
                                  .image-preview-container {
                                    max-width: 60%;
                                    height: 600px;
                                  }
                                  
                                  .image-preview {
                                    max-width: 100%;
                                    max-height: 100%;
                                    object-fit: contain;
                                  }
                            }
                            .pitch-deck {
                                .pdf-preview {
                                    display: flex;
                                    flex-direction: column;
                                    gap: 12px;
                                    .view-fullsize {
                                        color: #0988FF;
                                        padding-left: 4px;
                                        text-decoration: underline;
                                    }
                                }
                            }
                        }
                    }
                }
            }
            &-project-detail2 {
                margin-top: 72px;
                display: flex;
                flex-direction: column;
                gap: 16px;
                .box {
                    display: flex;
                    flex-direction: column;
                    gap: 16px;
                    border: 1px solid #9D9D9D;
                    border-radius: 4px;
                    padding: 8px;
                    h1 {
                        margin-left: 6px;
                    }
                    p {
                        // word-break: break-all;
                        margin-left: 16px;
                        padding-bottom: 8px;
                    }
                }
            }
        }
        .final {
            margin-top: 96px;
            .scoring-top {
                display: flex;
                flex-direction: column;
                gap: 24px;
                margin-bottom: 60px;
            }
        }
    }
}
.no-file {
    color: #9D9D9D;
    margin-left: 4px;
}
@media print {
    .no-print {
      display: none !important;
    }
    .project-info {
        .content {
            padding: 0;
            margin: 0;
        }
    }
    .final {
        .scoring-top {
            display: flex;
            flex-direction: column;
            gap: 24px;
            margin-bottom: 0;
        }
    }
  }

  .floating-arrow {
    display: flex;
    justify-content: end;
    position: sticky;
    bottom: 10px;
    margin-right: 20px;
    .arrow {
        margin-bottom: 20px;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 56px;
        height: 56px;
        background: linear-gradient(153.84deg, #3F52A7 17.76%, #1D266F 84.98%);
        border-radius: 32px;
        box-shadow: 0px 5px 15px 0px #00000012;
        color: white;
    }
    .arrow:hover {
        cursor: pointer;
        background: $primary;
    }
  }
  .ant-input-data-count {
    display: none;
  }
  .hide-data-count {
    display: none;
  }
  .data-count {
    margin-top: 2px;
    text-align: right;
    color: #9D9D9D;
  }
  .data-count.error {
    color: red;
  }
  .bottom-error {
    display: flex;
    justify-content: space-between;
  }