@import '../global.scss';

.container-final-summary {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
}

.final-summary {
    background: linear-gradient(183deg, #FFFFFF 8.01%, #E3F6FD 51.24%, #E3F6FD 78.99%, #67C8F4 97.49%);
    padding-bottom: 96px;
    flex: 1;

    .content {
        max-width: 1440px;
        margin: auto;
        padding: 96px 108px;

        .top {
            display: flex;
            flex-direction: column;

            &-logo {
                align-self: end;
            }

            &-breadcrumb {
                margin-top: 24px;

                span {
                    color: #666464;
                }

                .bread {
                    &-home {
                        color: #666464;
                    }

                    &-home:hover {
                        color: $primary;
                        cursor: pointer;
                    }

                    &-award-categories {
                        color: #666464;
                    }

                    &-award-categories:hover {
                        color: $primary;
                        cursor: pointer;
                    }

                    &-category {
                        color: #666464;
                    }

                    &-category:hover {
                        color: $primary;
                        cursor: pointer;
                    }
                }
            }

            &-title {
                margin-top: 72px;
                display: flex;
                align-items: center;
                gap: 8px;

                .status {
                    padding: 2px 4px;
                    background: #34AF47;
                    color: #ffffff;
                }

                img {
                    object-fit: contain;
                }
            }

            &-desc {
                margin-top: 24px;
            }

            &-btn {
                margin-top: 24px;
                border: 1px solid #666464;
                background: #ffffff;
                width: 184px;
                height: 42px;
                place-self: end;
                text-align: center;
                align-content: center;
                color: #000000;
                border-radius: 4px;
            }

            &-btn:hover {
                border-color: #1D266F;
                color: #1D266F;
                cursor: pointer;
            }
        }

        .main {
            margin-top: 48px;
            h1 {
                margin-top: 32px;
                margin-bottom: 20px;
                padding-left: 4px;
                color: #3F52A7;
            }
            table {
                border: 1px solid $primary;

                thead {
                    tr {
                        text-align: left;
                        background-color: $primary;
                        color: #ffffff;

                        th {
                            font-weight: 400;
                            padding: 15px 8px;
                        }

                        .topic {
                            &-id {
                                width: 135px;
                            }

                            &-tname {
                                width: 283px;
                            }

                            &-title {
                                width: 283px;
                            }

                            &-country {
                                width: 198px;
                            }

                            &-score {
                                width: 107px;
                            }

                            &-info {
                                text-wrap: nowrap;
                                width: 97px;
                            }
                            &-status {
                                text-align: center;
                                width: 115px;
                            }
                        }
                    }
                }

                tbody {
                    border: 1px solid $primary;
                    background-color: #ffffff;

                    tr {
                        td {
                            padding: 7px 8px;
                            border-right: 1px solid #9D9D9D;
                            border-bottom: 1px solid #9D9D9D;

                            &:last-child {
                                border-right: none;
                            }
                        }

                        .desc {
                            &-tname {
                                max-width: 283px;
                                overflow: hidden;
                                text-overflow: ellipsis;
                                text-wrap: nowrap;
                            }
                            &-title {
                                max-width: 283px;
                                overflow: hidden;
                                text-overflow: ellipsis;
                                text-wrap: nowrap;
                            }
                            &-status {
                                max-width: 115px;
                                text-align: center;
                            }
                            &-status-done {
                                background-color: #34AF47;
                                color: #ffffff;
                            }
                            &-status-undone {
                                background-color: red;
                                color: #ffffff;
                            }
                        }

                        // &.blue-border {
                        //     border-bottom: 1px solid $primary;
                        // }

                        // &:nth-child(3n) {
                        //     td {
                        //         border-bottom: 1px solid $primary;
                        //     }
                        // }

                        &:last-child {
                            td {
                                border-bottom: none;
                            }
                        }
                    }
                }

                button {
                    display: flex;
                    align-items: center;
                    gap: 4px;

                    span {
                        color: #0988FF;
                    }
                }
            }
        }
    }
}