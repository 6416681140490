@import '../global.scss';

.report-page-container {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
  }

.report-page {
    background: linear-gradient(183deg, #FFFFFF 8.01%, #E3F6FD 51.24%, #E3F6FD 78.99%, #67C8F4 97.49%);
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    .content {
        width: 600px;
        height: 608px;
        margin: auto;
        background: #FFFFFF;
        box-shadow: 4px 4px 14px 0px #00000040;
        align-content: center;
        .section {
            &-top {
                display: flex;
                flex-direction: column;
                gap: 32px;
                img {
                    margin: auto;
                }
                p span {
                    color: $primary;
                }
            }
            &-end {
                margin-top: 32px;
                display: flex;
                flex-direction: column;
                align-items: center;
                gap: 16px;
                button {
                    width: 393px;
                    height: 42px;
                    border-radius: 4px;
                }
                .btn {
                    &-download {
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        gap: 8px;
                        background: linear-gradient(153.84deg, #3F52A7 17.76%, #1D266F 84.98%);
                        color: white;
                    }
                    &-download:hover {
                        transition: none;
                        background: $primary;
                    }
                    &-download:active {
                        transition: none;
                        background: $primary;
                    }
                    &-logout {
                        border: 1px solid #666464;
                    }
                    &-logout:hover {
                        border: 1px solid #1D266F;
                        color: #1D266F;
                    }
                    &-logout:active {
                        border: 1px solid #1D266F;
                        color: #1D266F;
                    }
                }
            }
        }
    }
}