@import '../global.scss';

.success-page-container {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
  }

.success-page {
    background: linear-gradient(183deg, #FFFFFF 8.01%, #E3F6FD 51.24%, #E3F6FD 78.99%, #67C8F4 97.49%);
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    .content {
        width: 808px;
        margin: auto;
        .section {
            &-top {
                display: flex;
                flex-direction: column;
                gap: 30px;
                img {
                    margin: auto;
                }
                p span {
                    color: $primary;
                }
            }
            &-middle {
                margin-top: 48px;
                p a {
                    color: $primary;
                    text-decoration: underline;
                }
            }
            &-end {
                margin-top: 48px;
                // margin-bottom: 323px;
                button {
                    width: 393px;
                    height: 40px;
                    border: 1px solid #666464;
                    background: #ffffff;
                }
            }
        }
    }
}