@import '../global.scss';

.submission-table {
  margin-top: 24px;
  overflow-x: auto;

  table {
    width: 100%;
    background-color: white;
    border-collapse: collapse;
    border: 1px solid $primary;

    th, td {
      text-align: left;
      padding: 8px;
      border-left: 1px solid #9D9D9D;
    //   border-top: 1px solid #9D9D9D;
    }

    th {
      background-color: $primary;
      color: white;
      border-color: $primary;
    }
    td.team-name {
      max-width: 365px;
      overflow: hidden;
      text-overflow: ellipsis;
      text-wrap: nowrap;
    }
    td.project-name {
      max-width: 365px;
      overflow: hidden;
      text-overflow: ellipsis;
      text-wrap: nowrap;
    }
    .category {
      width: 182px;
      height: 120px;
      border-bottom: 1px solid $primary;
      border-left: 1px solid $primary;
    }
    .submitted {
      color: #0988FF;
    }
    .editBtn {
      vertical-align: text-bottom;
    }
  }
}
.width-135 {
    width: 135px;
}
.width-368 {
    width: 368px;
}
.width-98 {
    width: 98px;
}
.width-68 {
    width: 68px;
}
tr.row-0 {
    border-bottom: 1px solid #9D9D9D;
}
tr.row-1 {
    border-bottom: 1px solid #9D9D9D;
}
tr.row-2 {
    border-bottom: 1px solid $primary;
}
.edit {
  color: #000000;
  cursor: pointer;

}
.disabled {
    color: #9D9D9D;
    cursor: not-allowed;
}