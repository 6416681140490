@import '../../global.scss';

.presentation {
    background-color: #ffffff;
    &-content {
        padding: 86px 104px;
        .content {
            &-head2 {
                margin-top: 24px;
                display: flex;
                justify-content: space-between;
                padding-bottom: 24px;
                border-bottom: 1px solid $primary;
                .hint {
                    display: flex;
                    gap: 8px;
                }
            }
            &-scoring {
                margin-top: 48px;
                display: flex;
                flex-direction: column;
                gap: 36px;
                .attribute-item {
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    .attribute-left {
                        display: flex;
                        gap: 8px;
                        .attribute {
                            &-weight {
                                color: #666464;
                            }
                        }
                    }
                }
                .ant-select-selector {
                    width: 183px;
                    height: 48px;
                }
                .error-scoring .ant-select-selector {
                    border: 1px solid red !important;
                }
                .ant-select-arrow {
                    margin-top: 3px;
                    color: #000000;
                }
                .scoring-reason {
                    display: flex;
                    justify-content: space-between;
                    .topic-left {
                        display: flex;
                        gap: 8px;
                        .topic-h {
                            font-size: 18px;
                            color: #666464;
                        }
                    }
                    .input-textarea {
                        display: flex;
                        flex-direction: column;
                    }
                    #errorInput {
                        border: 1px solid red;
                        border-radius: 8px;
                    }
                    .ant-input-textarea-affix-wrapper {
                        width: 600px;
                        height: 120px;
                    }
                }
            }
            &-footer {
                margin-top: 50px;
                display: flex;
                align-items: center;
                justify-content: end;
                .footer-btn {
                    display: flex;
                    gap: 24px;
                    button {
                        width: 184px;
                        height: 42px;
                        border-radius: 4px;
                    }
                    .btn {
                        &-back {
                            border: 1px solid #666464;
                        }
                        &-back:hover {
                            border: 1px solid #1D266F;
                            color: #1D266F;
                        }
                        &-next {
                            background: linear-gradient(153.84deg, #3F52A7 17.76%, #1D266F 84.98%);
                            color: #ffffff;
                        }
                        &-next-error {
                            color: #9D9D9D;
                            border: 1px solid #9D9D9D;
                            cursor: not-allowed;
                    }
                        &-next:hover {
                            background: $primary;
                        }
                    }
                }
            }
        }
    }
}

.modal {
    .ant-modal-content {
        width: 600px;
        height: 356px;
        align-content: center;
        padding: 0;
    }
    &-content {
        display: flex;
        flex-direction: column;
        gap: 24px;
        text-align: center;
        align-items: center;
        padding: 0 78px;
        .button-section {
            margin-top: 24px;
            display: flex;
            gap: 24px;
            button {
                z-index: 10;
                width: 184px;
                height: 40px;
                border-radius: 4px;
            }
            .btn-back {
                border: 1px solid #666464;
                // display:none;
            }
            .btn-back:hover {
                border: 1px solid #1D266F;
                color: #1D266F;
            }
            .btn-submit {
                color: #ffffff;
                background: linear-gradient(153.84deg, #3F52A7 17.76%, #1D266F 84.98%);
            }
        
            .btn-submit:hover {
                background: $primary;
            }
        }
    }
}
.error-modal {
    .ant-modal-content {
        width: 600px;
        height: 586px;
        align-content: center;
        padding: 0;
    }
    &-content {
        display: flex;
        flex-direction: column;
        gap: 24px;
        text-align: center;
        align-items: center;
        padding: 0 78px;
        .button-section {
            margin-top: 24px;
            display: flex;
            gap: 24px;
            button {
                width: 184px;
                height: 40px;
                border-radius: 4px;
            }
            .btn-back {
                border: 1px solid #666464;
                // display:none;
            }
            .btn-submit {
                color: #ffffff;
                background: linear-gradient(153.84deg, #3F52A7 17.76%, #1D266F 84.98%);
            }
            .btn-submit:hover {
                background: $primary;
            }
        }
    }
}
.input-textarea-error .ant-input-outlined {
    border: none;
}