.footer {
    max-width: 100%;
    background: linear-gradient(180deg, #3F52A7 17.76%, #1D266F 84.98%);
    .content {
        padding: 32px 107px;
        color: white;
        display: flex;
        gap: 8px;
        p {
            align-content: center;
        }
        &-contact {
            gap: 16px;
            display: flex;
            justify-content: space-between;
            width: 100%;
            .left-box {
                display: flex;
                gap: 8px;
            }
            .email-box {
                display: flex;
                gap: 8px;
            }
            .email {
                align-content: center;
            }
            .right-box {
                display: flex;
                span {
                    align-content: center;
                }
                a {
                    text-decoration: underline;
                    cursor: pointer;
                }
            }
        }
    }
}

@media print {
    .no-print {
      display: none !important;
    }
  }
