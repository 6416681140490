@import '../../global.scss';
.ant-tabs-top>.ant-tabs-nav {
    margin: 0;
}

.ant-tabs-nav-wrap {
    background-color: $primary;
    justify-content: center;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
    height: 100px;
    .ant-tabs-nav-list {
        .ant-tabs-tab {
            width: 200px;
            justify-content: center;
            color: #ffffff;
            text-align: center;
        }
        .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
            color: #ffffff;
        }
    }
}
.ant-tabs .ant-tabs-ink-bar {
  background-color: #ffffff;
  height: 8px;
  padding: 4px;
  margin-bottom: 4px;
}

@media print {
    .no-print {
      display: none !important;
    }
    .ant-tabs-nav-wrap {
        display: none !important;
    }
  }