@import '../global.scss';

.container-project-list-categories {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
}

.project-list-categories {
    background: linear-gradient(183deg, #FFFFFF 8.01%, #E3F6FD 51.24%, #E3F6FD 78.99%, #67C8F4 97.49%);
    padding-bottom: 96px;
    flex: 1;

    .content {
        max-width: 1440px;
        margin: auto;
        padding: 96px 108px;

        .top {
            display: flex;
            flex-direction: column;

            &-logo {
                align-self: end;
            }

            &-breadcrumb {
                margin-top: 24px;

                span {
                    color: #666464;
                }

                .bread {
                    &-home {
                        color: #666464;
                    }

                    &-home:hover {
                        color: $primary;
                        cursor: pointer;
                    }

                    &-award-categories {
                        color: #666464;
                    }

                    &-award-categories:hover {
                        color: $primary;
                        cursor: pointer;
                    }
                }
            }

            &-title {
                margin-top: 72px;
                display: flex;
                align-items: center;
                gap: 8px;

                .status {
                    margin-top: 8px;
                    padding: 8px;
                    background: #34AF47;
                    color: #ffffff;
                }
                .status-undone {
                    margin-top: 8px;
                    padding: 8px;
                    background: #FF0000;
                    color: #ffffff;
                }

                img {
                    object-fit: contain;
                }
            }

            &-desc {
                margin-top: 24px;
            }

            &-btn {
                margin-top: 24px;
                background: linear-gradient(153.84deg, #3F52A7 17.76%, #1D266F 84.98%);
                width: 184px;
                height: 42px;
                place-self: end;
                text-align: center;
                align-content: center;
                color: #ffffff;
                border-radius: 4px;
            }

            &-btn:hover {
                background: $primary;
                cursor: pointer;
            }
        }

        .description {
            width: 848px;
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 3;
            -webkit-box-orient: vertical;
            white-space: normal;
        }

        .main {
            margin-top: 48px;

            .project {
                &-card {
                    padding-top: 24px;
                    display: flex;
                    gap: 24px;
                    padding-bottom: 24px;
                    border-bottom: 1px $primary solid;

                    .project-thumbnail {
                        position: relative;
                        width: 184px;
                        height: 152px;

                        img {
                            height: 152px;
                            border-radius: 4px;
                            // object-fit: contain;
                        }

                        .country-thumbnail {
                            position: absolute;
                            bottom: 60px;
                            right: 5px;
                        }
                    }

                    .project-details {
                        width: 1016px;
                        display: flex;
                        flex-direction: column;
                        gap: 16px;

                        .detail {
                            &-top {
                                display: flex;
                                justify-content: space-between;

                                h1 {
                                    max-width: 848px;
                                    overflow: hidden;
                                    text-overflow: ellipsis;
                                    display: -webkit-box;
                                    -webkit-line-clamp: 2;
                                    -webkit-box-orient: vertical;
                                    white-space: normal;
                                }

                                .total-score {
                                    display: flex;
                                    flex-direction: column;

                                    .score {
                                        &-point {
                                            text-align: end;
                                        }
                                    }
                                }
                            }

                            &-bottom {
                                display: flex;
                                justify-content: space-between;

                                .team-name {
                                    max-width: 477px;
                                    width: 477px;
                                    overflow: hidden;
                                    text-overflow: ellipsis;
                                    text-wrap: nowrap;
                                }

                                .team-id {
                                    width: 206px;
                                }

                                .state {
                                    &-status-done {
                                        padding: 8px;
                                        background: #34AF47;
                                        color: #ffffff;
                                    }

                                    &-status-not-done {
                                        padding: 8px;
                                        background: #FF0000;
                                        color: #ffffff;
                                    }
                                }
                            }
                        }
                    }
                }

                &-card:hover {
                    background-color: #D9D9D9;
                    cursor: pointer;
                }
            }
        }

        .ant-pagination {
            margin-top: 48px;
            float: inline-end;
            align-items: center;
        }

        .ant-pagination-item {
            background-color: transparent;
            width: 48px;
            height: 48px;
            align-content: center;
        }

        .ant-pagination-item-active {
            border-color: $primary;

            a {
                color: $primary;
            }
        }
    }
}

.success-modal {
    .ant-modal-content {
        width: 600px;
        height: 560px;
        align-content: center;
        padding: 0;
    }

    &-content {
        display: flex;
        flex-direction: column;
        gap: 24px;
        text-align: center;
        align-items: center;
        padding: 0 78px;

        .button-section {
            button {
                width: 184px;
                height: 42px;
                border-radius: 4px;
            }

            .btn-close {
                border: 1px solid #666464;
            }
            .btn-close:hover {
                border: 1px solid #1D266F;
                color: #1D266F;
            }
        }
    }
}