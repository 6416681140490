@import '../global.scss';
.ant-modal {
    .ant-modal-close-x {
        font-size: 21px;
    }
}

.loginModal .ant-modal-content {
    width: 600px;
    height: 565px;
    padding: 104px;
}

.login-modal {
    display: flex;
    flex-direction: column;
    align-items: center;
    .login {
        &-title {
            text-align: center;
        }
        &-desc {
            margin-top: 16px;
            text-align: center;
        }
        &-form {
            margin-top: 16px;
            display: flex;
            flex-direction: column;
            gap: 16px;
            // .forget-pwd {
            //     text-decoration: underline;
            //     color: $primary;
            //     align-self: flex-end;
            // }

            input:focus {
                border: 1px solid $primary;
            }
            input:hover {
                border: 1px solid $primary;
            }
        }
        &-email {
            display: flex;
            flex-direction: column;
            gap: 8px;
            .input-mail {
                border: 1px solid #9D9D9D;
                border-radius: 4px;
                width: 392px;
                height: 40px;
            }
            .input-mail-error {
                border: 1px solid #FF0000;
                border-radius: 4px;
                width: 392px;
                height: 40px;
            }
            .mail-error {
                padding-left: 4px;
                color: #FF0000;
            }
        }
        &-pwd {
            display: flex;
            flex-direction: column;
            gap: 8px;
            position: relative;
            .input-pwd {
                border: 1px solid #9D9D9D;
                border-radius: 4px;
                width: 392px;
                height: 40px;
            }
            .show-password-icon {
                position: absolute;
                right: 16px;
                margin-top: 12px;
                svg path {
                    color: #9D9D9D;
                }
            }
            .show-password-icon-err {
                position: absolute;
                right: 16px;
                margin-top: 12px;
                svg path {
                    color: red;
                }
            }
            .input-pwd-error {
                border: 1px solid #FF0000;
                border-radius: 4px;
                width: 392px;
                height: 40px;
            }
            .pwd-error {
                padding-left: 4px;
                color: #FF0000;
            }
        }
        &-btn {
            display: flex;
            align-items: center;
            justify-content: center;
            background: linear-gradient(180.84deg, #3F52A7 17.76%, #1D266F 84.98%);
            color: white;
            width: 393px;
            height: 40px;
            border-radius: 4px;
        }
        &-btn:hover {
            background: $primary;
        }
    }
}

//start loading Icon
@keyframes rotate {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
  
  .rotate {
    animation: rotate 1s linear infinite;
  }
//end loading Icon