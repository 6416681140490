@import '../global.scss';
.center {
    top: 27%;
  }
.logout-modal .ant-modal-content {
    align-content: center;
    width: 600px;
    height: 386px;
    padding: 0;
}

.logout-modal {
    .logout-modal-content {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 24px;
        padding: 0 78px;
        h1 {
            text-align: center;
        }
        p {
            text-align: center;
        }
        .button-section {
            margin-top: 24px;
            display: flex;
            gap: 24px;
            button {
                width: 184px;
                height: 40px;
                border-radius: 4px;
            }
            .btn {
                &-cancel {
                    border: 1px solid #666464;
                    color: #000000;
                    background: white;
                }
                &-cancel:hover {
                    border: 1px solid #1D266F;
                    color: #1D266F;
                }
                &-submit {
                    background: linear-gradient(180.84deg, $primary 17.76%, #1D266F 84.98%);
                    color: white;
                }
                &-submit:hover {
                    transition: none;
                    background: $primary;
                }
                &-submit:active {
                    transition: none;
                    background: $primary;
                }
            }
        }
    }
}