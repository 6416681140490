@import '../global.scss';

.landing-page-container {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.landing {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  background: linear-gradient(183deg, #FFFFFF 8.01%, #E3F6FD 51.24%, #E3F6FD 78.99%, #67C8F4 97.49%);
  .content {
    display: flex;
    flex-direction: column;
    align-items: center;
    // margin-top: 96px;
    &-title {
      margin-top: 16px;
    }
    &-desc {
      margin-top: 32px;
      text-align: center;
    }
    button {
      margin-top: 48px;
      background: linear-gradient(180.84deg, $primary 17.76%, #1D266F 84.98%);
      color: white;
      width: 393px;
      height: 40px;
      border-radius: 4px;
    }
    button:active {
      background: $primary;
    }
    .ant-btn {
      transition: none;
    }
    .ant-btn-primary:hover {
      background: $primary;
    }
  }
}