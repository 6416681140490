@import '../global.scss';
.overview {
    background: linear-gradient(183deg, #FFFFFF 8.01%, #E3F6FD 51.24%, #E3F6FD 78.99%, #67C8F4 97.49%);
    .content {
        padding: 96px 108px;
        .top {
            display: flex;
            flex-direction: column;
            &-logo {
                align-self: end;
            }
            &-breadcrumb {
                margin-top: 24px;
                span {
                    color: #666464;
                }
                .bread {
                    &-home {
                        color: #666464;
                    }
                    &-home:hover {
                        color: $primary;
                        cursor: pointer;
                    }
                }
            }
            &-title {
                margin-top: 72px;
                display: flex;
                gap: 8px;
                img {
                    object-fit: contain;
                }
            }
            &-desc {
                margin-top: 24px;
            }
            &-instruction {
                margin-top: 24px;
                display: flex;
                gap: 24px;
            }
            &-button {
                align-self: end;
                .upload-btn {
                    margin-top: 48px;
                    width: 184px;
                    height: 40px;
                    display: flex;
                    justify-content: center;
                    gap: 4px;
                    background: linear-gradient(180.84deg, #3F52A7 17.76%, #1D266F 84.98%);
                    border-radius: 4px;
                    svg {
                        align-self: center;
                        color: white;
                    }
                    a {
                        align-self: center;
                        color: white;
                    }
                }
                .upload-btn:hover {
                    background: $primary;
                }
                .upload-btn:active {
                    background: $primary;
                }
            }
        }
    }
}