@import '../global.scss';

.award-categories {
    background: linear-gradient(183deg, #FFFFFF 8.01%, #E3F6FD 51.24%, #E3F6FD 78.99%, #67C8F4 97.49%);

    .content {
        max-width: 1440px;
        margin: auto;
        padding: 96px 108px;

        .top {
            display: flex;
            flex-direction: column;

            &-logo {
                align-self: end;
            }

            &-breadcrumb {
                margin-top: 24px;

                span {
                    color: #666464;
                }

                .bread {
                    &-home {
                        color: #666464;
                    }

                    &-home:hover {
                        color: $primary;
                        cursor: pointer;
                    }
                }
            }

            &-title {
                margin-top: 72px;
                display: flex;
                gap: 8px;

                img {
                    object-fit: contain;
                }
            }

            &-desc {
                margin-top: 24px;
            }
            &-btn {
                margin-top: 24px;
                background: linear-gradient(153.84deg, #3F52A7 17.76%, #1D266F 84.98%);
                width: 184px;
                height: 42px;
                place-self: end;
                text-align: center;
                align-content: center;
                color: #ffffff;
                border-radius: 4px;
            }

            &-btn:hover {
                background: $primary;
                cursor: pointer;
            }
        }

        .main {
            margin-top: 48px;
            display: flex;
            flex-wrap: wrap;
            gap: 24px;
            width: 1224px;

            .category-card {
                max-width: 392px;
                max-height: 254px;

                .cards {
                    width: 392px;
                    height: 254px;
                    // padding: 24px;
                    display: flex;
                    flex-direction: column;
                    justify-content: space-between;
                    background-color: #ffffff;
                    box-shadow: 4px 4px 14px 0px #00000040;
                    border: 1px solid #9D9D9D;
                    border-radius: 4px;
                    cursor: pointer;

                    .card {
                        &-top {
                            display: flex;
                            gap: 24px;
                            align-items: center;
                            justify-content: space-between;
                            padding: 24px 0 0 24px;
                            h1 {
                                text-wrap: nowrap;
                            }
                            .status {
                                margin-left: auto;
                                padding: 4px;
                                margin-right: 12px;
                                background: #34AF47;
                                color: #ffffff;
                            }
                            .status-undone {
                                margin-left: auto;
                                padding: 4px;
                                margin-right: 12px;
                                background: #FF0000;
                                color: #ffffff;
                                text-wrap: nowrap;
                            }
                        }

                        &-main {
                            p {
                                margin: auto;
                                width: 344px;
                                overflow: hidden;
                                text-overflow: ellipsis;
                                display: -webkit-box;
                                -webkit-line-clamp: 3;
                                -webkit-box-orient: vertical;
                                white-space: normal;
                            }
                        }
                        &-bottom {
                            padding: 0 0 24px 24px;
                        }
                    }
                }

                .cards:hover {
                    border: 1px solid $primary;
                }
            }
        }
    }
}

.not-allow-card {
    filter: grayscale(100%);
    opacity: 0.7;
    cursor: not-allowed !important;
}