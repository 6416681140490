.additional-files {
    margin-bottom: 20px;
  }
  
  .title-1 {
    font-size: 1.5rem;
    font-weight: bold;
    margin-bottom: 10px;
  }
  
  .file-list {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }
  
  .file-item {
    width: 495px;
    height: 40px;
    display: flex;
    align-items: center;
    padding: 10px;
    border: 1px solid #9D9D9D;
    background-color: #EEEEEE33;
    border-radius: 4px;
    cursor: pointer;
  }
  .no-files {
    margin-left: 4px;
    color: #9D9D9D;
  }
  .file-item:hover {
    background-color: #e0e0e0;
  }
  
  .file-icon {
    margin-right: 10px;
  }
  
  .image-preview-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.7);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
  }
  
  .image-preview-container {
    max-width: 90%;
    max-height: 90%;
  }
  
  .image-preview {
    max-width: 100%;
    max-height: 100%;
    object-fit: contain;
  }