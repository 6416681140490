@import '../global.scss';
.projectsubmit {
    background: linear-gradient(183deg, #FFFFFF 8.01%, #E3F6FD 51.24%, #E3F6FD 78.99%, #67C8F4 97.49%);
    .content {
        padding: 96px 108px;
        .top {
            display: flex;
            flex-direction: column;
            &-logo {
                align-self: end;
            }
            &-breadcrumb {
                margin-top: 24px;
                span {
                    color: #666464;
                }
                .bread {
                    &-home {
                        cursor: pointer;
                        color: #666464;
                    }
                    &-home:hover {
                        color: $primary;
                    }
                    &-overview {
                        cursor: pointer;
                        color: #666464;
                    }
                    &-overview:hover {
                        color: $primary;
                    }
                }
                .ant-btn {
                    padding: 0;
                    background-color: transparent;
                    color: #666464;
                    box-shadow: none;
                    font-size: 16px;
                }
                .ant-btn {
                    span:hover {
                        color: $primary;
                    }
                }
            }
            &-title {
                margin-top: 72px;
                display: flex;
                gap: 8px;
                img {
                    object-fit: contain;
                }
            }
            &-desc {
                margin-top: 24px;
            }
        }
        .form-section {
            margin-top: 48px;
            box-shadow: 4px 4px 24px 0px #00000014;
            background: white;
            label {
                width: 220px;
                text-align: right;
            }
            .project-form {
                padding: 48px 81px;
                .identity-infomation {
                    .ant-input {
                        width: 600px;
                        height: 40px;
                    }
                    .ant-input-affix-wrapper {
                        width: 600px;
                        height: 40px;
                        padding: 0 11px;
                    }
                    .ant-select {
                        width: 600px;
                        height: 40px;
                    }
                    .info-identity {
                        margin-top: 32px;
                        display: flex;
                        flex-direction: column;
                        gap: 24px;
                        .identity-field {
                            display: flex;
                            align-items: start;
                            gap: 24px;
                            label {
                                margin-top: 8px;
                            }
                            img {
                                margin-top: 12px;
                            }
                        }
                        .identity-field-member {
                            display: flex;
                            align-items: start;
                            gap: 24px;
                            label {
                                margin-top: 8px;
                            }
                            .team-member {
                             &-field {
                                display: flex;
                                flex-direction: column;
                                gap: 24px;
                             }
                             &-input {
                                display: flex;
                                align-items: center;
                                gap: 24px;
                             }
                            }
                            img.tooltips-member {
                                margin-top: 12px;
                            }
                        }
                        .ant-btn {
                            width: 80px;
                            height: 40px;
                        }
                        .add-btn {
                            display: flex;
                            gap: 24px;
                            label {
                                width: 220px;
                            }
                        }
                    }
                }
                .project-infomation {
                    margin-top: 48px;
                    .ant-input {
                        width: 600px;
                        height: 40px;
                    }
                    .ant-input-affix-wrapper {
                        width: 600px;
                    }
                    .info-project {
                        margin-top: 32px;
                        display: flex;
                        flex-direction: column;
                        gap: 24px;
                        .project-field {
                            display: flex;
                            align-items: start;
                            gap: 24px;
                            label {
                                margin-top: 8px;
                            }
                            img {
                                margin-top: 12px;
                            }
                        }
                        .project-area-field-desc {
                            height: max-content !important;
                            display: flex;
                            align-items: start;
                            gap: 24px;
                            textarea {
                                width: 600px;
                                height: 160px;
                               }
                        }
                        .project-area-field-url {
                            display: flex;
                            align-items: start;
                            gap: 24px;
                            label {
                                margin-top: 8px;
                            }
                            img {
                                margin-top: 12px;
                            }
                        }
                        .project-area-field {
                            height: 120px;
                            display: flex;
                            align-items: start;
                            gap: 24px;
                            height: max-content !important;
                            textarea {
                                width: 600px;
                                height: 120px;
                               }
                        }
                    }
                }
                .upload-infomation {
                    margin-top: 48px;
                    .ant-input {
                        width: 600px;
                        height: 40px;
                    }
                    .info-upload {
                        margin-top: 32px;
                        display: flex;
                        flex-direction: column;
                        gap: 24px;
                        .error-image {
                            display: flex;
                            gap: 24px;
                            p {
                                margin-top: -16px;
                                margin-left: 4px;
                                width: 184px;
                                color: #FF0000;
                            }
                        }
                    }
                    .upload-field {
                        display: flex;
                        align-items: start;
                        gap: 24px;
                        label {
                                margin-top: 8px;
                            }
                            img {
                                margin-top: 12px;
                            }
                        .ant-upload.ant-upload-select {
                            button {
                                width: 180px;
                                height: 40px;
                            }
                        }
                        .ant-upload-list.ant-upload-list-text {
                            color: #0988FF;
                        }
                        .upload-error .ant-upload-list-item-name {
                            color: red;
                        }
                        .upload-error .ant-upload-icon {
                            svg {
                                color: red;
                            }
                        }
                        .error-text {
                            color: red;
                        }
                    }
                    .upload-field-image {
                        display: flex;
                        align-items: start;
                        gap: 24px;
                    }
                }
            }
            .form-btn {
                display: flex;
                gap: 24px;
                justify-content: end;
                padding: 48px 104px;
                button {
                    width: 184px;
                    height: 40px;
                    border-radius: 4px;
                }
                .btn {
                    &-cancel {
                        border: 1px solid #666464;
                        color: #000000;
                        background: white;
                    }
                    &-submit {
                        background: linear-gradient(180.84deg, $primary 17.76%, #1D266F 84.98%);
                        color: white;
                    }
                    &-submit:hover {
                        transition: none;
                        background: $primary;
                    }
                    &-submit:active {
                        transition: none;
                        background: $primary;
                    }
                }
            }
        }
        .input-container {
            display: flex;
            flex-direction: column;
            align-items: start !important;
            gap: 0px !important;
          }
          .input-container .error-text {
            margin-top: 4px;
            padding-left: 8px;
            color: red;
          }
          .error .ant-select-selector {
            border: 1px solid red;
          }
          .errorInput {
            border: 1px solid red;
          }
          #errorInput {
            border: 1px solid red;
            border-radius: 8px;
          }
    }
}
.input-container-error .ant-input-outlined {
    border: none;
}

.text-red {
    color: red;
}
.text-gray {
    color: #666464;
}
.upload-tooltip {
    width: 700px;
}