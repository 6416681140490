@import '../../global.scss';

.judging {
    background-color: #ffffff;
    &-content {
        padding: 86px 104px;
        .content {
            &-head {
                display: flex;
                justify-content: space-between;
                button {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    gap: 8px;
                    width: 221px;
                    height: 42px;
                    border: 1px solid #666464;
                    border-radius: 4px;
                }
                button:hover {
                    border: 1px solid #1D266F;
                    color: $primary;
                }
            }
            &-head2 {
                margin-top: 24px;
                display: flex;
                justify-content: space-between;
                padding-bottom: 24px;
                border-bottom: 1px solid $primary;
                .un-scored {
                    padding: 4px;
                    color: white;
                    background-color: #FF0000;
                }
                .success-scored {
                    padding: 4px;
                    color: white;
                    background-color: #34AF47;
                }
                .hint {
                    align-items: center;
                    display: flex;
                    gap: 8px;
                }
            }
            &-table {
                margin-top: 24px;
                table {
                    width: 100%;
                    border-collapse: collapse;
                    .text-center {
                        text-align: center;
                    }
                    .text-scored {
                        color: #1D266F;
                    }
                    th, td {
                        border: 1px solid $primary;
                        padding: 12px;
                        text-align: left;
                    }
                    th {
                        text-align: center;
                    }
                }
            }
            &-scoring {
                margin-top: 48px;
                display: flex;
                justify-content: center;
                button {
                    z-index: 10;
                }
                .btn-start {
                    width: 393px;
                    height: 42px;
                    background: linear-gradient(180deg, #3F52A7 17.76%, #1D266F 84.98%);
                    border-radius: 4px;
                    color: #ffffff;
                }
                .btn-start:hover {
                    background: $primary;
                }
            }
            &-scoring-scored {
                margin-top: 48px;
                display: flex;
                justify-content: space-between;
                align-items: center;
                width: 100%;
                button {
                    z-index: 10;
                }
                    .btn-edit {
                        width: 184px;
                        height: 42px;
                        background: linear-gradient(180deg, #3F52A7 17.76%, #1D266F 84.98%);
                        border-radius: 4px;
                        color: #ffffff;
                    }
                    .btn-edit:hover {
                        background: $primary;
                    }
            }
        }
    }
}
.center {
    top: 27%;
  }
.edit-warning-modal .ant-modal-content {
    align-content: center;
    width: 600px;
    height: 386px;
    padding: 0;
}

.edit-warning-modal {
    .edit-warning-modal-content {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 24px;
        padding: 0 78px;
        h1 {
            text-align: center;
        }
        p {
            text-align: center;
        }
        .button-section {
            margin-top: 24px;
            display: flex;
            gap: 24px;
            button {
                width: 184px;
                height: 40px;
                border-radius: 4px;
            }
            .btn {
                &-cancel {
                    border: 1px solid #666464;
                    color: #000000;
                    background: white;
                }
                &-cancel:hover {
                    border: 1px solid #1D266F;
                    color: #1D266F;
                }
                &-submit {
                    background: linear-gradient(180.84deg, $primary 17.76%, #1D266F 84.98%);
                    color: white;
                }
                &-submit:hover {
                    transition: none;
                    background: $primary;
                }
                &-submit:active {
                    transition: none;
                    background: $primary;
                }
            }
        }
    }
}
@media print {
    .no-print {
      display: none !important;
    }
    .judging {
        &-content {
            margin: 0;
            padding: 0;
        }
    }
  }
  .ant-tooltip-placement-topRight >.ant-tooltip-arrow {
    right: 0px;
  }
  .ant-tooltip-content {
      .ant-tooltip-inner {
        width: 519px;
        }
    }