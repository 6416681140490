@import '../global.scss';

.submit-modal .ant-modal-content {
    align-content: center;
    width: 600px;
    height: 341px;
    padding: 0;
}

.submit-modal {
    .submit-modal-content {
        display: flex;
        flex-direction: column;
        gap: 24px;
        padding: 0 78px;
        h1 {
            text-align: center;
        }
        p {
            text-align: center;
        }
        .button-section {
            margin-top: 24px;
            display: flex;
            gap: 24px;
            button {
                width: 392px;
                height: 40px;
                border-radius: 4px;
            }
            .btn {
                &-cancel {
                    border: 1px solid #666464;
                    color: #000000;
                    background: white;
                }
                &-submit {
                    background: linear-gradient(180.84deg, $primary 17.76%, #1D266F 84.98%);
                    color: white;
                }
                &-submit:hover {
                    transition: none;
                    background: $primary;
                }
                &-submit:active {
                    transition: none;
                    background: $primary;
                }
            }
        }
    }
}