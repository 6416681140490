@tailwind base;
@tailwind components;
@tailwind utilities;

//fonts-setup
@font-face {
  font-family: 'Roboto';
  src: url('../public/fonts/Roboto-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Roboto';
  src: url('../public/fonts/Roboto-Medium.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
}

//fonts-size
* {
  margin: 0;
  padding: 0;
  font-family: 'Roboto';
}
p {
  margin: 0;
  padding: 0;
}

input {
  font-size: 16px;
  padding: 8px 16px;
  outline: none;
}

.H {
  &1 {
    font-size: 46px;
    font-weight: 500;
    line-height: 45px;
    letter-spacing: 0;
  }
  &2 {
    font-size: 34px;
    font-weight: 500;
    line-height: 36px;
    letter-spacing: 0;
  }
}

.Title {
  &-1 {
    font-size: 26px;
    font-weight: 500;
    line-height: 28px;
    letter-spacing: 0;
  }
  &-2 {
    font-size: 26px;
    font-weight: normal;
    line-height: 28px;
    letter-spacing: 0;
  }
}

.Subtitle {
  font-size: 18px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0;
  &-1 {
  font-size: 22px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0;
  }
  &-2 {
  font-size: 20px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0;
  }
}

.Body {
  font-size: 18px;
  font-weight: normal;
  line-height: 24px;
  letter-spacing: 0;
}

.Small-caption {
  font-size: 14px;
  font-weight: normal;
  line-height: 24px;
  letter-spacing: 0;
}

//colors
$primary: rgba(63, 82, 167, 1);
